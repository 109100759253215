/* Google Font CDN Link */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

footer {
  background: #140b5c;
  width: 100%;
  bottom: 0;
  left: 0;
 
  font-family: "Poppins", sans-serif;
}

footer .content_footer {
  max-width: 1250px;
  margin: auto;
  padding: 30px 40px 40px 40px;
}
footer .content_footer .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}
.content_footer .top .logo-details {
  color: #fff;
  font-size: 30px;
}
.content_footer .top .media-icons {
  display: flex;
}
.content_footer .top .media-icons a {
  height: 40px;
  width: 40px;
  margin: 0 8px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  color: #fff;
  font-size: 17px;
  text-decoration: none;
  transition: all 0.4s ease;
}
.top .media-icons a:nth-child(1) {
  background: #4267b2;
}
.top .media-icons a:nth-child(1):hover {
  color: #4267b2;
  background: #fff;
}
.top .media-icons a:nth-child(2) {
  background: #1da1f2;
}
.top .media-icons a:nth-child(2):hover {
  color: #1da1f2;
  background: #fff;
}
.top .media-icons a:nth-child(3) {
  background: #e1306c;
}
.top .media-icons a:nth-child(3):hover {
  color: #e1306c;
  background: #fff;
}
.top .media-icons a:nth-child(4) {
  background: #0077b5;
}
.top .media-icons a:nth-child(4):hover {
  color: #0077b5;
  background: #fff;
}
.top .media-icons a:nth-child(5) {
  background: #ff0000;
}
.top .media-icons a:nth-child(5):hover {
  color: #ff0000;
  background: #fff;
}
footer .content_footer .link-boxes {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
/* footer .content_footer .link-boxes .box {
  width: calc(100% / 5 - 10px);
} */
.content_footer .link-boxes .box .link_name {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
  position: relative;
}
.link-boxes .box .link_name::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
  width: 35px;
  background: #fff;
}
.content_footer .link-boxes .box li {
  margin: 6px 0;
  list-style: none;
}
.content_footer .link-boxes .box li a {
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  text-decoration: none;
  opacity: 0.8;
  transition: all 0.4s ease;
}
.content_footer .link-boxes .box li a:hover {
  opacity: 1;
  text-decoration: underline;
}
.content_footer .link-boxes .input-box {
  margin-right: 55px;
}
.link-boxes .input-box input {
  height: 40px;
  width: calc(100% + 55px);
  outline: none;
  border: 2px solid #afafb6;
  background: #140b5c;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  color: #fff;
  margin-top: 5px;
}
.logo-details{
  display: flex;
  align-items: center;
}
.logo-details img{
  width: 40px;
}
.link-boxes .input-box input::placeholder {
  color: #afafb6;
  font-size: 16px;
}
.link-boxes .input-box input[type="button"] {
  background: #fff;
  color: #140b5c;
  border: none;
  font-size: 18px;
  font-weight: 500;
  margin: 4px 0;
  opacity: 0.8;
  cursor: pointer;
  transition: all 0.4s ease;
}
.input-box input[type="button"]:hover {
  opacity: 1;
}
footer .bottom-details {
  width: 100%;
  background: #0f0844;
}
footer .bottom-details .bottom_text {
  max-width: 1250px;
  margin: auto;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
}
.bottom-details .bottom_text span,
.bottom-details .bottom_text a {
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  opacity: 0.8;
  text-decoration: none;
}
.bottom-details .bottom_text a:hover {
  opacity: 1;
  text-decoration: underline;
}
.bottom-details .bottom_text a {
  margin-right: 10px;
}
@media (max-width: 900px) {
  footer .content_footer .link-boxes {
    flex-wrap: wrap;
  }
  footer .content_footer .link-boxes .input-box {
    width: 40%;
    margin-top: 10px;
  }
}
@media (max-width: 700px) {
 
  footer {
    position: relative;
    width: 100%;
  }
  .content_footer .top .logo-details {
    font-size: 26px;
  }
  .content_footer .top .media-icons a {
    height: 35px;
    width: 35px;
    font-size: 14px;
    line-height: 35px;
  }
  footer .content_footer .link-boxes .box {
    width: calc(100% / 3 - 10px);
  }
  footer .content_footer .link-boxes .input-box {
    width: 60%;
  }
  .bottom-details .bottom_text span,
  .bottom-details .bottom_text a {
    font-size: 12px;
  }
}
@media (max-width: 520px) {
  footer .content_footer {
    max-width: 1250px;
    margin: auto;
    padding: 0px;
    padding-top: 10px;
}
  footer::before {
    top: 145px;
  }
  footer .content_footer .top {
    flex-direction: column;
  }
  .content_footer .top .media-icons {
    margin-top: 16px;
  }
  footer .content_footer .link-boxes .box {
    width: calc(100% / 2 - 10px);
    width: 100%;

  }
  footer .content_footer .link-boxes .input-box {
    width: 100%;
  }
}
