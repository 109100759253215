.name_container {
  width: 100vw;
  height: 30vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: 40px;
}
.homepage_root_container:active.topnav {
  opacity: 0.4;
}
.display_slider2_merinasoft {
  display: none;
}

.the_title {
  font-size: 20px;
  font-weight: bold;
}

.the_name {
  font-size: 50px;
  font-weight: bold;
  color: #660c7c;
  letter-spacing: 2px;
  font-family: serif;
}

.our_services_container {
  display: flex;
  flex-direction: column;
  row-gap: 1vw;
  align-items: center;
  justify-content: center;
}
.our_services_item_list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 15px;
  column-gap: 2vw;
}
.service_header {
  margin-bottom: 2vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5vw;
  font-size: 1.6vw;
}
.service_iamge {
  box-shadow: 0px 0px 50px #dfffc4;
  background-color: #508620 !important;
  width: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4vw;
  border-radius: 50%;
}
.service_image {
  box-shadow: 0px 0px 50px #c5fbff;
  background-color: #7be6ee !important;
  width: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4vw;
  border-radius: 50%;
}
.service_image3 {
  box-shadow: 0px 0px 50px #fcffb2;
  background-color: #dbe133 !important;
  width: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4vw;
  border-radius: 50%;
}
.service_header img {
  width: 70%;
}
.service_image4 {
  box-shadow: 0px 0px 50px #f8d2d7;
  background-color: #f87385 !important;
  width: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4vw;
  border-radius: 50%;
}
.service_image5 {
  box-shadow: 0px 0px 50px #eddfff;
  background-color: #b888f8 !important;
  width: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4vw;
  border-radius: 50%;
}
.service_image6 {
  box-shadow: 0px 0px 50px #ffdec9;
  background-color: #ff9655 !important;
  width: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4vw;
  border-radius: 50%;
}
.display_slider_merinasoft {
  display: none;
}

.our_services_item {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;
  height: 300px;
  width: 27%;
  display: flex;
  flex-direction: column;
  padding: 7px 10px;
  color: #0f0413;
  border-radius: 1vw;
}
.service-description {
  text-align: left;
  color: #202020;
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
}
.our_services_img {
  width: 80%;
  height: 40%;
  margin-top: 10%;
  padding-left: 10%;
  padding-right: 10%;
}

.our_services_header {
  color: white;
}
.organizations_clinet {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
}
.image_main_content_clinet {
  background-image: url("../images/Path-18@2x.png");
  background-size: 100% 100%;
  padding-left: 3.2rem;
  padding-right: 3rem;
  /* padding-top: 5rem; */
  padding-bottom: 5rem;
  height: 50vw;
}
.our_clients_says {
  background-color: rgb(243, 187, 230);
  /* width: 80%; */
  height: 60vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 7vw;
  margin-right: 7vw;
  margin-bottom: 4vw;
  border-radius: .8vw;
  column-gap: 2vw;
}
.our_clients_says .clinet_animation_div {
  width: 40%;
}
.clinet_animation_div img {
  width: 300px;
  animation: bounce-x 10s infinite;
}

.our_services_para {
  height: 60%;
  text-align: left;
  color: white;
  padding-left: 20%;
}

.name_container p {
  font-size: 5em;
  font-weight: 800;
  font-family: "Josefin Sans", "sans serif";
  color: rgb(202, 15, 202);
  text-shadow: #fc0 1px 0 10px;
}
.title_who_had {
  color: #000;
  font-size: 45px;
  font-weight: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headline span {
  background: linear-gradient(0.25turn, #9a17cd, #8f1bac, #7e21a0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.proud_member_besis {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40vw;
  background-color: #f8f8f8;
  padding: 4vw;
  border-radius: 1vw;
}
.first_column {
  display: flex;
  flex-direction: column;
  row-gap: 1vw;
  width: 44%;
}
.first_column .contact p {
  font-size: 1.25rem;
  letter-spacing: -0.05rem;
}
.first_column .address p {
  font-size: 1.25rem;
  letter-spacing: -0.05rem;
}
.gap {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  line-height: 1;
  font-size: 1.25rem;
  letter-spacing: -0.05rem;
}
.proud_member_besis img {
  width: 100%;
  height: 500px;
}
.contact_form {
  background-color: #f8f8f8;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  padding: 2rem 1rem;
  width: 83vw;
  margin-left: 7vw;
  display: flex;
  column-gap: 4vw;
  margin-top: 3vw;
}
.input_field_contact_form {
  display: flex;
  column-gap: 1vw;
  margin-bottom: 1vw;
}
.input_field_contact_form input {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 3px;

  padding-left: 0.3vw;
  background-color: #ffffff;
  width: 20vw;
  height: 5vh;
  border: none;
  border-color: #b1afaf;
  color: #000;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.input_field_contact_form select {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 3px;

  padding-left: 0.3vw;
  background-color: #ffffff;
  width: 20.4vw;
  height: 5vh;
  border: none;
  border-color: #b1afaf;
  color: #000;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.text_area textarea {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 3px;
  padding-left: 0.3vw;
  background-color: #ffffff;
  width: 41.3vw;
  height: 19vh;
  border: none;
  border-color: #b1afaf;
  color: #000;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  /* resize: vertical; */
  font-family: inherit;
}
.submit_button button {
  margin-top: 2vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 41.7vw;
  height: 3vw;
  font-size: 1.4vw;
  color: #e9e8e8;
  padding-left: 0.15vw;
  background: linear-gradient(0.25turn, #9a17cd, #8f1bac, #7e21a0);
  border: none;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  cursor: pointer;
  border-radius: 6px;
}
.title_line {
  background: #9a17cd;
  width: 95px;
  height: 8px;
  margin-bottom: 25px;
}
.technology_used_list img {
  max-width: 100%;
  max-height: 100%;
  width: 180px;
}
.technology_name {
  width: 40%;
  height: 10vh;
  display: flex;
  align-items: center;
}

.technology_used_list {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin-left: 7vw;
  gap: 20px;
  margin-bottom: 45px;
}
.simple_title {
  text-align: left;
  margin-bottom: 15px;
  margin-top: 30px;
  margin-left: 8vw;
  font-size: 2.5rem;
}

.simple_title .service_title {
  background: linear-gradient(0.25turn, #9a17cd, #660c7c, #391047);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.radiant_clients {
  background: linear-gradient(0.25turn, #9a17cd, #660c7c, #391047);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}
.floating_whatsup {
  position: fixed;
  bottom: 20px;
  right: 20px;
}
.title_right {
  /* width: 50%; */
  text-align: right;
}
.title_right img {
  width: 300px;
}

.title_right img {
  animation: bounce-x 10s infinite;
}
.vision_container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 80vw;
  margin-left: 8vw;
  margin-right: 10vw;
}
.slider-wrapper {
  display: flex;
  overflow: hidden;
  padding: 2vw;
  gap: 1vw;
  flex-direction: row;
}
.content {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  width: 27vw;
  height: 25vw;
  background: linear-gradient(0.25turn, #e6cef0, #e6cef0, #e6cef0);
  border-radius: 1vw;
  padding: 1vw;
}
.content p {
  color: #4a4a4a;
  font-size: 23px;
  font-weight: 400;
  line-height: 42px;
}
.ourvision_header {
  width: 100%;
  text-align: left;
  font-weight: bold;
}
.ourvision_header span {
  background: linear-gradient(0.25turn, #9a17cd, #660c7c, #391047);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.section_of_certificate {
  display: flex;

  align-items: center;
  justify-content: center;
  width: 100%;
}
.small_clent {
  display: none;
}

.ourvision_desc {
  width: 100%;
  text-align: justify;
  line-height: 35px;
  font-size: 20px;
}
.animation_title {
  visibility: visible;
  animation-delay: 300ms;
  animation-name: myAnimation;
}
.md_clent img {
  display: none;
}
.content-slider {
  width: 70%;
  height: 360px;
}
.slider {
  height: 320px;
  width: 45vw;
  margin: 30px auto 0;
  overflow: visible;
  position: relative;
}
.mask {
  overflow: hidden;
  height: 320px;
  width: 45vw;
}
.slider ul {
  margin: 0;
  padding: 0;
  position: relative;
  width: 3400px; /* 680px * number of slides */
}
.slider li {
  width: 680px;
  height: 320px;
  position: absolute;
  left: 680px;
  list-style: none;
}
.slider .quote {
  font-size: 30px;
}
.slider .source {
  font-size: 20px;
  text-align: right;
}
.slider li:hover {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.slider li.anim1 {
  -webkit-animation: cycle1 15s linear infinite;
  animation: cycle1 15s linear infinite;
}
.slider li.anim2 {
  -webkit-animation: cycle2 15s linear infinite;
  animation: cycle2 15s linear infinite;
}
.slider li.anim3 {
  -webkit-animation: cycle3 15s linear infinite;
  animation: cycle3 15s linear infinite;
}
.slider li.anim4 {
  -webkit-animation: cycle4 15s linear infinite;
  animation: cycle4 15s linear infinite;
}
.slider li.anim5 {
  -webkit-animation: cycle5 15s linear infinite;
  animation: cycle5 15s linear infinite;
}
@keyframes cycle1 {
  0%,
  20% {
    left: 0px;
    opacity: 1;
  }
  24%,
  100% {
    left: -20px;
    opacity: 0;
  }
}
@keyframes cycle2 {
  0%,
  20% {
    left: 20px;
    opacity: 0;
  }
  24%,
  40% {
    left: 0px;
    opacity: 1;
  }
  44%,
  100% {
    left: -20px;
    opacity: 0;
  }
}
@keyframes cycle3 {
  0%,
  40% {
    left: 20px;
    opacity: 0;
  }
  44%,
  60% {
    left: 0px;
    opacity: 1;
  }
  64%,
  100% {
    left: -20px;
    opacity: 0;
  }
}
@keyframes cycle4 {
  0%,
  60% {
    left: 20px;
    opacity: 0;
  }
  64%,
  80% {
    left: 0px;
    opacity: 1;
  }
  84%,
  100% {
    left: -80px;
    opacity: 0;
  }
}
@keyframes cycle5 {
  0%,
  80% {
    left: 20px;
    opacity: 0;
  }
  84%,
  100% {
    left: 0px;
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  animation: fadeInUp 1s ease forwards;
}

@keyframes slideInLeft {
  from {
    transform: translateX(-10%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Define CSS animation for moving elements from top to bottom */
@keyframes slideInTop {
  from {
    transform: translateY(-10%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Apply the animation to elements */
.animated-element-left {
  animation: slideInLeft 0.5s ease-in-out forwards;
}

.animated-element-top {
  animation: slideInTop 0.5s ease-in-out forwards;
}
/* @media screen and (max-width: 500px) {
  .homepage_root_container {
    height: auto !important;
    min-height: 100vh;
    width: 100%;
    margin-bottom: 50px;
  }

  .merinasoft_wallpaper {
    height: 600px;
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
    margin-bottom: 100px;
  }
} */

@media screen and (max-width: 575px) {
  .homepage_root_container {
    height: auto !important;
    min-height: 100vh;
    width: 89%;
    margin-bottom: 50px;
  }
  .name_container {
    width: 98vw;
    height: 30vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    margin-top: 10px;
    margin-left: 3vw;
  }
  .slideshow-container {
    max-width: 1300px;
    position: relative;
    margin: auto;
    width: 82%;
  }
  .ourvision_header {
    font-size: 1.5rem;
  }
  .the_title {
    font-size: 20px;
    font-weight: bold;
  }

  .the_name {
    font-size: 44px;
    font-weight: bold;
    color: #660c7c;
    letter-spacing: 2px;
    font-family: serif;
  }

  .merinasoft_wallpaper {
    height: 600px;
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
    margin-bottom: 100px;
  }
  /* .big_clent img {
    display: none;
  } */
  .md_clent img {
    display: block;
  }

  .big_clent {
    display: none;
  }

  .small_clent {
    display: block;
  }
  .small_clent img {
    width: 100%;
  }

  .vision_container {
    width: 83vw;
    /* margin-left: 1.6vw; */
  }
  .ourvision_desc {
    font-size: 16px;
  }
  .our_services_container {
    flex-direction: column;
    margin-left: 3vw;
  }
  .our_services_item_list {
    flex-direction: column;
    row-gap: 5px;
    padding: 0;
    /* margin-left: 3vw; */
    column-gap: 2vw;
    padding-left: 8vw;
    width: 121%;
  }
  .our_services_item {
    height: auto;
    width: 90%;
    margin-left: 0;
    padding: 6vw;
    margin-bottom: 4vw;
  }

  .our_services_item .service_text {
    font-size: 18px;
    color: #000;
    margin-left: 15px;
  }
  .service_image4 {
    width: 14vw;

    height: 14vw;
  }
  .service_image1 {
    width: 14vw;

    height: 14vw;
  }
  .service_image3 {
    width: 14vw;

    height: 14vw;
  }
  .service_image {
    width: 14vw;

    height: 14vw;
  }
  .service_iamge {
    width: 14vw;

    height: 14vw;
  }
  .service_image5 {
    width: 14vw;

    height: 14vw;
  }
  .service_image6 {
    width: 14vw;

    height: 14vw;
  }
  .our_clients_says {
    height: 68vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 14px;
    margin-bottom: 4vw;
    border-radius: 1vw;
    row-gap: 100px;
    width: 98%;
    margin-left: 6vw;
  }

  .proud_member_besis {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 83vw;
  }
  .our_clients_says .clinet_animation_div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title_line {
    margin-top: 10vw;
  }
  .slider .quote {
    font-size: 16px;
    width: 80vw;
  }

  .slider {
    height: 320px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    overflow: visible;
    position: relative;
  }

  .mask {
    overflow: hidden;
    height: 320px;
    width: 80vw;
  }

  .content-slider {
    width: 100%;
    height: 165px;
  }
  .contact_form {
    padding: 1rem 1rem;
    width: 90vw;
    /* margin-left: 1vw; */
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    justify-content: center;
    column-gap: 4vw;
    margin-top: 3vw;
    margin-left: 4vw;
  }
  .first_column {
    display: flex;
    flex-direction: column;
    row-gap: 1vw;
    width: 100%;
  }

  .second_column_contact_form {
    display: flex;
    flex-direction: column;
    row-gap: 1vw;
    width: 100%;
  }

  .input_field_contact_form {
    display: flex;
    column-gap: 1vw;
    margin-bottom: 1vw;
    width: 100%;
  }

  .input_field_contact_form input {
    width: 42vw;
  }
  .input_field_contact_form select {
    width: 43vw;
  }
  /* .slider {
    margin: 20px;
  } */
  .text_area textarea {
    width: 86vw;
    height: 19vh;
  }

  .submit_button button {
    border-radius: 1vw;
    width: 86vw;
    height: 10vw;
    font-size: 5vw;
  }

  .custom-footer {
    height: 200px;
    width: 80vw;
    background-color: black;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 40px;
    bottom: 0;
    text-align: center;
  }

  .slider li {
    width: 5%;
    height: 0px;
  }

  .slider li .source {
    font-size: 12px;
  }

  .display_slider2_merinasoft {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 75px;
  }

  .clinet_animation_div img {
    width: 200px;
  }
  .technology_used_list {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: center;
    margin-left: 0vw;
    gap: 5px;
    margin-bottom: 45px;
    width: 96%;
    margin-left: 14vw;
  }
  .technology_name {
    width: 60%;
    height: 10vh;
    display: flex;
    align-items: center;
  }
  .simple_title {
    text-align: left;
    margin-bottom: 10px;
    margin-left: 6vw;
    font-size: 1.5rem;
  }
  .image_main_content_clinet {
   
    height: auto;
    margin-left: 0vw;
    padding-left: 2.5vw;
    padding-right: 0vw;

  }
  .title_who_had {
    color: #000;
    font-size: 18px;
    font-weight: 1000;

    flex-direction: column;
    align-items: center;
    justify-content: center;
    column-gap: 10vw;
  }
  .title_right {
    width: 50;
  }
  .title_right img {
    width: 300px;
  }
  .name_container p {
    font-size: 3rem;
    font-weight: bold;
    font-family: "Josefin Sans", "sans serif";
    color: rgb(202, 15, 202);
    text-shadow: #fc0 1px 0 10px;
  }
  
  .section_of_certificate {
    padding-left: 4vw;
    margin-left: 4vw;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .homepage_root_container {
    height: auto !important;
    min-height: 100vh;
    width: 100%;
    margin-bottom: 50px;
  }
  .display_slider2_merinasoft {
    display: flex;
  }

  .merinasoft_wallpaper {
    height: 600px;
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
    margin-bottom: 100px;
  }
  .our_clients_says {
    flex-direction: column;
    height: auto;
    margin: 1vw;
    padding: 1vw;
  }
  .content-slider {
    width: 90%;
    height: 250px;
  }
  .slider {
    height: 320px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    overflow: visible;
    position: relative;
  }
  .mask {
    overflow: hidden;
    height: 320px;
    width: 100%;
  }
  .slider li {
    width: 604px;
    height: 260px;
    position: absolute;
    left: 680px;
    list-style: none;
  }
  .slider .quote {
    font-size: 20px;
  }
  .our_services_item_list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 2vw;
  }
  .our_services_item {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;
    height: 191px;
    width: 88%;
    display: flex;
    flex-direction: column;
    padding: 7px 39px;
    color: #0f0413;
    border-radius: 1vw;
  }
  .vision_container {
    margin: 2vw;
    width: 95vw;
  }
  .organizations_clinet {
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 1vw;
  }
  .technology_used_list {
    display: grid;
    grid-template-columns: auto auto auto auto;
    margin-left: 1vw;
    gap: 5px;
    margin-bottom: 45px;
  }
  .image_main_content_clinet {
    width: 85%;
    height: auto;
  }
  .title_right {
    width: 70%;
    text-align: right;
  }
  .title_who_had {
    font-size: 23px;
  }
  .title_right img {
    width: 100%;
  }
  .contact_form {
    width: 93%;
    margin: 1vw;
  }
  .slideshow-container {
    width: 100%;
  }
  .simple_title {
    margin-left: 2vw;
  }
  .text_area textarea {
    width: 46vw !important;
    height: 19vh;
    font-size: 23px !important;
  }
  .input_field_contact_form input {
    width: 22.1vw !important;
  }
  .input_field_contact_form select {
    width: 22.7vw !important;
  }
  .submit_button button {
    width: 47vw !important;
    height: 6vw !important;
    font-size: 15px !important;
  }
  .proud_member_besis {
    width: 60vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .homepage_root_container {
    height: auto !important;
    min-height: 100vh;
    width: 100%;
    margin-bottom: 50px;
  }
  .vision_container {
    margin-left: 3vw;
    width: 90vw;
  }
  .simple_title {
    margin-left: 2vw;
  }
  .merinasoft_wallpaper {
    height: 600px;
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
    margin-bottom: 100px;
  }
  .display_slider1_merinasoft {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ourvision_desc {
    width: 100%;
    text-align: justify;
    line-height: 40px;
    font-size: 30px;
  }
  .our_services_item_list {
    display: grid;
    grid-template-columns: auto;
    row-gap: 2vw;
  }
  .our_services_item {
    width: 95%;
    height: auto;
    padding: 20px;
  }
  .service_header {
    font-size: 32px;
    color: rgb(0, 0, 0);
    font-weight: bold;
  }
  .service-description {
    font-size: 30px;
    line-height: 40px;
    font-weight: normal;
  }
  .service_image4 {
    width: 14vw;

    height: 14vw;
  }
  .service_image1 {
    width: 14vw;

    height: 14vw;
  }
  .service_image3 {
    width: 14vw;

    height: 14vw;
  }
  .service_image {
    width: 14vw;

    height: 14vw;
  }
  .service_iamge {
    width: 14vw;

    height: 14vw;
  }
  .service_image5 {
    width: 14vw;

    height: 14vw;
  }
  .service_image6 {
    width: 14vw;

    height: 14vw;
  }
  .our_clients_says {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 14px;
    margin-bottom: 4vw;
    border-radius: .9vw;
    width: 83%;

    padding-bottom: 30px;
  }

  .proud_member_besis {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 83vw;
  }
  .our_clients_says .clinet_animation_div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title_line {
    margin-top: 10vw;
  }
  .slider .quote {
    font-size: 30px;
    width: 100%;
  }

  .slider {
    height: 400px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    overflow: visible;
    position: relative;
  }

  .mask {
    overflow: hidden;
    height: 400px;
    width: 80vw;
  }

  .content-slider {
    width: 100%;
    height: auto;
  }
  .slider li {
    width: 600px;
  }
  .slider li .source {
    margin-top: 10px;
    font-size: 30px;
    text-align: center;
  }
  .clinet_animation_div img {
    width: 70%;
  }
  .technology_used_list {
    display: grid;
    margin-left: 0vw;
    gap: 5px;
    margin-bottom: 45px;
    width: 100%;
    margin-left: 0vw;
    padding-left: 15px;
  }
  .technology_name {
    width: 75%;
    height: 10vh;
    display: flex;
    align-items: center;
  }
  .title_who_had {
    color: #000;
    font-size: 30px;
    font-weight: 1000;
    align-items: flex-start;
    justify-content: center;
    column-gap: 0vw;
  }
  .title_left {
    width: 100%;
  }
  .title_right {
    width: 50;
  }
  .title_right img {
    width: 300px;
  }
  .image_main_content_clinet {
    height: auto;
  }
  .big_clent img {
    width: 100%;
    height: 300px;
  }
  .contact_form {
    padding: 1rem 1rem;
    width: 84vw;
    /* margin-left: 1vw; */
    display: grid;
    grid-template-columns: auto !important;
    align-items: center;
    justify-content: center;
    column-gap: 4vw;
    margin-top: 3vw;
    margin-left: 4vw;
  }

  .first_column {
    display: flex;
    flex-direction: column;
    row-gap: 1vw;
    width: 100%;
  }

  .second_column_contact_form {
    display: flex;
    flex-direction: column;
    row-gap: 1vw;
    width: 100%;
  }

  .input_field_contact_form {
    display: flex;
    column-gap: 1vw;
    margin-bottom: 1vw;
    width: 100%;
  }

  .input_field_contact_form input {
    width: 40.5vw !important;
    font-size: 23px !important;
  }
  .input_field_contact_form select {
    width: 41vw !important;
    font-size: 23px !important;
  }
  /* .slider {
  margin: 20px;
} */
  .text_area textarea {
    width: 82vw !important;
    height: 19vh;
    font-size: 23px !important;
  }
  .contact h3 {
    font-size: 34px;
  }
  .address h3 {
    font-size: 34px;
  }
  .first_column .contact p {
    font-size: 2rem;
    letter-spacing: -0.05rem;
  }
  .first_column .address p {
    font-size: 2rem;
    letter-spacing: -0.05rem;
  }
  .gap {
    line-height: 1;
    font-size: 2rem;
    letter-spacing: -0.05rem;
  }
  .submit_button button {
    border-radius: 1vw;
    width: 82.5vw !important;
    height: 10vw !important;
    font-size: 30px !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .homepage_root_container {
    height: auto !important;
    min-height: 100vh;
    width: 100%;
    margin-bottom: 50px;
  }

  .merinasoft_wallpaper {
    height: 600px;
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
    margin-bottom: 100px;
  }
  .our_services_item {
    height: 300px;
  }
  .display_slider_merinasoft {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 7.5vw;
  }
  .image_main_content_clinet {
    height: 55vw;
  }
  .title_who_had {
    font-size: 30px;
  }
  .slider .quote {
    font-size: 20px;
  }
  .our_clients_says .clinet_animation_div {
    width: 44%;
  }
}

@media screen and (min-width: 1200px) {
  .homepage_root_container {
    height: auto !important;
    min-height: 100vh;
    width: 100%;
    margin-bottom: 50px;
  }

  .merinasoft_wallpaper {
    height: 600px;
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
    margin-bottom: 100px;
  }
  .display_slider_merinasoft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-top: 5vw;
  }
  .mask {
    overflow: hidden;
    height: 320px;
    width: 57vw;
  }
  .slider .quote {
    font-size: 25px;
  }
  .slider li {
    width: 600px;
  }
  body {
    overflow-x: hidden;
  }
}

@keyframes myAnimation {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
  }
}

@keyframes bounce-x {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
