.root_container {
    font-family: "Josefin Sans", "sans serif";
    font-size: 1.2rem;
}

.root_container Button {
    background-color: #5A563A;
}



@media screen and (max-width: 575px) {
    .root_container {
        margin-top: 10vh;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card_container {
        width: 100%;
        justify-content: center;
    }

    .card {
        background: rgb(255, 255, 255);
        border-radius: 10px;
        width: 70%;
        box-shadow: 3px 3px 3px 3px #9E9E9E;
        display: flex;
        flex-direction: row;
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 15px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 10px;
        justify-content: center;

    }

    .card_text {
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-right: 15px;
    }

    .card_header {
        font-size: x-large;
        font-weight: 700;
        margin-top: 10px;
    }

    .card_image {
        width: 40%;
        height: 40vh;
        margin-top: 10px;
        margin: 10px;
        border: 1px solid black;
    }

    .card_description {
        text-align: left;
    }

    /*new*/


    .card_text_i {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .card_header_i {
        width: 100%;
        text-align: justify;
        font-size: x-large;
        margin-left: 10px;
        font-weight: 700;
        margin-top: 10px;
    }

    .card_image_containeri {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        margin: 10px;
        justify-content: center;
    }

    .card_image_i {
        width: 33%;
        height: 40vh;
        margin-right: 2px;
    }

    .direction_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 20px;
    }

    .button {
        margin-top: 2px;
        margin-bottom: 2px;
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .root_container {
        margin-top: 10vh;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card_container {
        width: 100%;
        justify-content: center;
    }

    .card {
        background: rgb(255, 255, 255);
        border-radius: 10px;
        width: 70%;
        box-shadow: 3px 3px 3px 3px #9E9E9E;
        display: flex;
        flex-direction: row;
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 15px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 10px;
        justify-content: center;

    }

    .card_text {
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-right: 15px;
    }

    .card_header {
        font-size: x-large;
        font-weight: 700;
        margin-top: 10px;
    }

    .card_description {
        text-align: left;
    }

    .card_image {
        width: 40%;
        margin-top: 10px;
        margin: 10px;
        border: 1px solid black;
    }

    /*new*/

    .card_i {
        background: rgb(255, 255, 255);
        border-radius: 10px;
        width: 90%;
        height: 30vh;
        box-shadow: 3px 3px 3px 3px #9E9E9E;
        display: flex;
        flex-direction: column;
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 15px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 10px;
    }

    .card_text_i {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .card_header_i {
        width: 100%;
        text-align: justify;
        font-size: x-large;
        margin-left: 10px;
        font-weight: 700;
        margin-top: 10px;
    }


    .card_image_containeri {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        margin: 10px;
        justify-content: center;
    }

    .card_image_i {
        width: 33%;
        height: 350px;
        margin-right: 2px;
    }

    .direction_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 20px;
    }

    .button {
        margin-top: 2px;
        margin-bottom: 2px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .root_container {
        margin-top: 10vh;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card_container {
        width: 100%;
        justify-content: center;
    }

    .card {
        background: rgb(255, 255, 255);
        border-radius: 10px;
        width: 70%;
        box-shadow: 3px 3px 3px 3px #9E9E9E;
        display: flex;
        flex-direction: row;
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 15px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 10px;
        justify-content: center;

    }

    .card_text {
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-right: 15px;
    }

    .card_header {
        font-size: x-large;
        font-weight: 700;
        margin-top: 10px;
    }

    .card_description {
        text-align: left;
    }

    .card_image {
        width: 40%;
        margin-top: 10px;
        margin: 10px;
        border: 1px solid black;
    }

    /*new*/

    .card_i {
        background: rgb(255, 255, 255);
        border-radius: 10px;
        width: 90%;
        height: 30vh;
        box-shadow: 3px 3px 3px 3px #9E9E9E;
        display: flex;
        flex-direction: column;
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 15px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 10px;
    }

    .card_text_i {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .card_header_i {
        width: 100%;
        text-align: justify;
        font-size: x-large;
        margin-left: 10px;
        font-weight: 700;
        margin-top: 10px;
    }

    /* .card_description_i {
        width: 100%;
        font-size: 1.2rem;
        text-align: justify;
        margin-left: 10px;
    } */

    .card_image_containeri {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        margin: 10px;
        justify-content: center;
    }

    .card_image_i {
        width: 33%;
        height: 350px;
        margin-right: 2px;
    }


    .direction_container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 20px;
    }

    .button {
        margin-left: 2px;
        margin-right: 2px;
    }


    .direction_container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 20px;
    }

    .button {
        margin-left: 2px;
        margin-right: 2px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .root_container {
        margin-top: 10vh;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card_container {
        width: 100%;
        justify-content: center;
    }

    .card {
        background: rgb(255, 255, 255);
        border-radius: 10px;
        width: 70%;
        box-shadow: 3px 3px 3px 3px #9E9E9E;
        display: flex;
        flex-direction: row;
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 15px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 10px;
        justify-content: center;

    }

    .card_text {
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-right: 15px;
    }

    .card_header {
        font-size: x-large;
        font-weight: 700;
        margin-top: 10px;
    }

    .card_image {
        width: 40%;
        height: 40vh;
        margin-top: 10px;
        margin: 10px;
        border: 1px solid black;
    }

    .card_description {
        text-align: left;
    }

    /*new*/

    .card_i {
        background: rgb(255, 255, 255);
        border-radius: 10px;
        width: 90%;
        box-shadow: 3px 3px 3px 3px #9E9E9E;
        display: flex;
        flex-direction: column;
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 15px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 10px;
    }

    .card_text_i {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .card_header_i {
        width: 100%;
        text-align: justify;
        font-size: x-large;
        margin-left: 10px;
        font-weight: 700;
        margin-top: 10px;
    }

    .card_image_containeri {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        margin: 10px;
        justify-content: center;
    }

    .card_image_i {
        width: 33%;
        height: 350px;
        margin-right: 2px;
    }


    .direction_container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 20px;
    }

    .button {
        margin-left: 2px;
        margin-right: 2px;
    }
}

@media screen and (min-width: 1200px) {
    .root_container {
        margin-top: 10vh;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .card_container {
        width: 100%;
        justify-content: center;
    }

    .card {
        background: rgb(255, 255, 255);
        border-radius: 10px;
        width: 70%;
        box-shadow: 3px 3px 3px 3px #9E9E9E;
        display: flex;
        flex-direction: row;
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 15px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 10px;
        justify-content: center;

    }

    .card_text {
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-right: 15px; 
    }

    .card_header {
        font-size: x-large;
        font-weight: 700;
        margin-top: 10px;
        margin-left: 20px;
        text-align: center;
    }

    .card_image {
        width: 15%;
        height: 35vh;
        margin-top: 10px;
        margin: 10px;
        border: 1px solid black;
    }

    .card_i {
        background: rgb(255, 255, 255);
        border-radius: 10px;
        width: 90%;
        box-shadow: 3px 3px 3px 3px #9E9E9E;
        display: flex;
        flex-direction: column;
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 15px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 10px;
    }

    .card_text_i {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .card_header_i {
        width: 100%;
        text-align: justify;
        font-size: x-large;
        margin-left: 10px;
        font-weight: 700;
        margin-top: 10px;
    }

    .card_image_containeri {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        margin: 10px;
        justify-content: center;
    }

    .card_image_i {
        width: 33%;
        height: 350px;
        margin-right: 2px;
    }

    .card_description {
        text-align: left;
        margin-left: 30px;
    }

    .direction_container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 20px;
    }

    .button {
        margin-left: 2px;
        margin-right: 2px;
    }
}