@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
.mySlides {
  display: none;
}
img {
  vertical-align: middle;
  width: 100%;
}
.offer_main_content {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Slideshow container */
.slideshow-container {
  max-width: 1300px;
  position: relative;
  margin: auto;
}
.active {
  display: block;
}
/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
}
.slider_1 {
  background: linear-gradient(0.25turn, #c991df, #c993d6, #d192e7);
  width: 80vw;
  height: 60vh;
 
 
}
.content_slider_merinasoft {
  display: flex;
  align-items: center;
  justify-content: center;
}
.second_content_slider {
  width: 40%;
}
.left_conetnt_offer {
  width: 50%;
}
.right_conetnt_offer {
  width: 40%;
  margin-top: 3vw;
}
.right_conetnt_offer img {
  width: 80%;
  animation-name: effectbuttom;
  animation-duration: 5s;
}
.second_slider_image img {
  width: 80%;
  animation-name: effectbuttom;
  animation-duration: 5s;
}
.offer_content_slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1vw;
}
.offer_content_slider .offer_title {
  color: white;
  font: 700 normal 2.4em "tahoma";
  margin-left: 1vw;
}
.image_first img {
  width: 100%;
  height: 200px;
  margin-top: 5vw;
  animation-name: effectRight;
  animation-duration: 5s;
}
.content_slider_merinasoft .first_content {
  display: flex;
  padding: 2vw;
  padding-top: 8vw;
}

.slider_2 {
  background: linear-gradient(0.25turn, #c068e2, #b85dcf, #b66dd1);
  width: 80vw;
  height: 60vh;
 
 
}
.slider_3 {
  background: linear-gradient(0.25turn, #b174c9, #9c41b3, #ae4ad3);
  width: 80vw;
  height: 60vh;
 
 
}
/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */

.active {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* On smaller screens, decrease text size */

.word {
  margin: auto;
  color: rgb(45, 35, 187);
  font: 700 normal 2em "tahoma";
  /* text-shadow: 5px 2px #222324, 2px 4px #222324, 3px 5px #222324; */
}
h1 {
  max-width: 40ch;
  text-align: center;
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
@keyframes scale {
  100% {
    transform: scale(1);
  }
}

span {
  display: inline-block;
  opacity: 0;
  filter: blur(4px);
}

span:nth-child(1) {
  animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(2) {
  animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(3) {
  animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(4) {
  animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(5) {
  animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(6) {
  animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(7) {
  animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(8) {
  animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(9) {
  animation: fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(10) {
  animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(11) {
  animation: fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(12) {
  animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(13) {
  animation: fade-in 0.8s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(14) {
  animation: fade-in 0.8s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(15) {
  animation: fade-in 0.8s 1.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(16) {
  animation: fade-in 0.8s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(17) {
  animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(18) {
  animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes effectLeft {
  0% {
    transform: translateX(-20vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}
@keyframes effectLeft {
  0% {
    transform: translateX(-10vw);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes effectRight {
  0% {
    transform: translateX(10vw);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes effectTop {
  0% {
    transform: translateY(-2vw);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes effectbuttom {
  0% {
    transform: translateY(10vw);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@media screen and (max-width: 575px) {
  /* .slideshow-container {
    margin-left: 3vw;
  } */
  .slider_2 {
    width: 100vw;
    height: 115vw;
    padding: 1vw;
  }
  .slider_1 {
    width: 100vw;
    height: 115vw;
    padding: 1vw;
  }
  .slider_3 {
    width: 100vw;
    height: 115vw;
    padding-top: 10vw;
  }
  .content_slider_merinasoft {
    flex-direction: column;
    row-gap: 0;
  }
  .first_content h1 {
    font-size: 20px;
    color: rgb(24, 23, 23);
  }
  .second_content_slider {
    margin-top: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
  }
  .second_content_slider h1 {
    font-size: 20px;
  }
  .image_first img {
    width: 100%;
    height: 200px;
  }
  .second_slider_image img {
    width: 100%;
    height: 250px;
  }
  .offer_main_content {
    flex-direction: column;
  
    width: 100%;
  }
  .offer_main_content .left_conetnt_offer {
    height: 100px;
    width: 100%;
  }
  .offer_main_content .right_conetnt_offer {
    width: 80%;
  }
  .right_conetnt_offer img {
    width: 100%;
    height: 200px;
  }
  .offer_content_slider .offer_title {
    color: white;
    font: 700 normal 20px "tahoma";
    margin-left: 1vw;
  }
  .word1 {
    font-size: 19px;
  }
}

/* Style the dots below the slider */
.dots_container {
  text-align: center;
  margin-top: 10px;
  margin-left: 40px;
}

.dot {
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  cursor: pointer;
}

.dot.active {
  background-color: #717171;
}

.dot:hover {
  background-color: #717171;
}


@media screen and (min-width: 576px) and (max-width: 767px) {
  /* .slideshow-container {
    margin-left: 3vw;
  } */
  .slider_2 {
    height: 50vw;
    padding: 1vw;
    width: 94vw;
    margin-left: 2vw;
  }
  .slider_1 {
    height: 60vw;
    padding: 1vw;
    width: 94vw;
    margin-left: 2vw;
  }
  .slider_3 {
    height: 50vw;
    padding-top: 10vw;
    width: 94vw;
    margin-left: 2vw;
  }
  .content_slider_merinasoft {
    flex-direction: column;
    row-gap: 0;
  }
  .first_content h1 {
    font-size: 24px;
  }
  .second_content_slider {
    margin-top: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .second_content_slider h1 {
    font-size: 24px;
  }
  .image_first img {
    width: 100%;
    height: 200px;
    margin: 0;
  }
  .second_slider_image img {
    width: 100%;
    height: 250px;
  }
  .offer_main_content {
    flex-direction: column;

    width: 100%;
  }
  .offer_main_content .left_conetnt_offer {
    height: 100px;
    width: 100%;
  }
  .offer_main_content .right_conetnt_offer {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .right_conetnt_offer img {
    width: 40%;
    height: 100%;
  }
  .offer_content_slider .offer_title {
    color: white;
    font: 700 normal 25px "tahoma";
    margin-left: 1vw;
  }
  .word {
    font-size: 24px;
  }
}
@media screen and (min-width: 769px) and (max-width: 991px) {
  .slider_2 {
    height: 70vw;
    padding: 1vw;
  }
  .slider_1 {
    height: 76vw;
    padding: 1vw;
  }
  .slider_3 {
    height: 70vw;
    padding-top: 10vw;
  }
  .content_slider_merinasoft {
    flex-direction: column;
    row-gap: 0;
  }
  .first_content h1 {
    font-size: 30px;
  }
  .second_content_slider {
    margin-top: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .second_content_slider h1 {
    font-size: 30px;
  }
  .image_first img {
    width: 100%;
    height: 300px;
  }
  .second_slider_image img {
    width: 100%;
    height: 400px;
  }
  .offer_main_content {
    flex-direction: column;
    row-gap: 3vw;
    width: 100%;
  }
  .offer_main_content .left_conetnt_offer {
    height: 100px;
    width: 100%;
  }
  .offer_main_content .right_conetnt_offer {
    width: 80%;
  }
  .right_conetnt_offer img {
    width: 100%;
    height: 300px;
  }
  .offer_content_slider .offer_title {
    color: white;
    font-size: 35px;
    font-weight: bold;
    margin-left: 1vw;
  }
  .word {
    font-size: 32px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .slideshow-container {
    margin-left: 1vw;
  }
  .offer_main_content {
    flex-direction: column;
  }
  .content_slider_merinasoft {
    flex-direction: column;
  }
  .left_conetnt_offer {
    margin-top: 200px;
    width: 80%;
  }
  .right_conetnt_offer {
    width: 80%;
    margin-top: 40px;
  }
}
