@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
.mySlides {
  display: none;
}
img {
  vertical-align: middle;
  width: 100%;
}
.offer_main_content1 {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
/* Slideshow container */
.slideshow-container {
  max-width: 1300px;
  position: relative;
}
.mySlides.active {
  display: block;
  animation: fadeEffect 1s ease-in-out;
}

@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.active {
  display: block;
}
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.next {
  right: -280px;
  border-radius: 3px 0 0 3px;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.dots-container {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 8px;
  left: 100px;
}

.dot.active,
.dot:hover {
  background-color: #717171;
}
/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 15px;
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
}
.slider_11 {
  background: linear-gradient(0.25turn, #c991df, #c993d6, #d192e7);
  width: 100vw;
  height: 70vh;
  box-shadow: 0px 0px 6px #757373;
  background-image: url("../images/banner2.jpg");
  background-size: cover;
}
.content_slider_merinasoft11 {
  position: absolute;
  top: 10%;
  left: 10%;

}
.content_slider_merinasoft12 {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
}
.second_content_slider1 {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  margin-top: 150px;
  margin-right: 10px;
  width: 40%;
}
.left_conetnt_offer1 {
  width: 50%;
}
.right_conetnt_offer {
  width: 40%;
  margin-top: 3vw;
}
.right_conetnt_offer img {
  width: 80%;
  animation-name: effectbuttom;
  animation-duration: 5s;
}
.second_slider_image img {
  width: 80%;
  animation-name: effectTop;
  animation-duration: 5s;
}
.second_slider_image {
  margin-left: 5vw;
}
.heading_second_slide {
  font-family: "Poppins", sans-serif;
  padding-right: 0px;
  line-height: 60px;
  font-weight: 400;
  font-size: 27px;
  margin-right: 100px;
}
.heading_second_slide h1 {
  text-align: end;
  color: white;
}
.heading2_second_slide {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  font-size: 25px;
  text-align: end;
  margin-right: 100px;
  color: white;
}
.offer_content_slider1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1vw;
  margin-top: 150px;
}
.offer_content_slider1 .offer_title {
  color: white;
  font: 700 normal 2.4em "tahoma";
  margin-left: 1vw;
}
.image_first img {
  width: 100%;
  height: 300px;
  margin-top: 5vw;
  animation-name: effectRight;
  animation-duration: 5s;
}
.content_slider_merinasoft11 .first_content {
  display: flex;
  padding: 2vw;
  padding-top: 8vw;
  color: white !important;
}

.slider_12 {
  background: linear-gradient(0.25turn, #c991df, #c993d6, #d192e7);
  width: 100vw;
  height: 70vh;
  box-shadow: 0px 0px 6px #757373;
  background-image: url("../images/1.png");
  background-size: cover;
}
.slider_13 {
  background: linear-gradient(0.25turn, #c991df, #c993d6, #d192e7);
  width: 100vw;
  height: 70vh;
  box-shadow: 0px 0px 6px #757373;
  background-image: url("../images/2.jpg");
  background-size: cover;
}
/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */

/* .active {
  background-color: #717171;
} */

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* On smaller screens, decrease text size */

.word1 {
  margin: auto;
  color: white;
  font: 700 normal 2em "tahoma";
  /* text-shadow: 5px 2px #222324, 2px 4px #222324, 3px 5px #222324; */
  animation-name: fade;
  animation-duration: 1.5s;
}
h1 {
  max-width: 40ch;
  text-align: center;
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
@keyframes scale {
  100% {
    transform: scale(1);
  }
}

span {
  display: inline-block;
  opacity: 0;
  filter: blur(4px);
}

span:nth-child(1) {
  animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(2) {
  animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(3) {
  animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(4) {
  animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(5) {
  animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(6) {
  animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(7) {
  animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(8) {
  animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(9) {
  animation: fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(10) {
  animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(11) {
  animation: fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(12) {
  animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(13) {
  animation: fade-in 0.8s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(14) {
  animation: fade-in 0.8s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(15) {
  animation: fade-in 0.8s 1.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(16) {
  animation: fade-in 0.8s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(17) {
  animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(18) {
  animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes effectLeft {
  0% {
    transform: translateX(-20vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}
@keyframes effectLeft {
  0% {
    transform: translateX(-10vw);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes effectRight {
  0% {
    transform: translateX(4vw);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes effectTop {
  0% {
    transform: translateY(-2vw);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes effectbuttom {
  0% {
    transform: translateY(4vw);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@media only screen and (max-width: 300px) {
  .text {
    font-size: 11px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .slideshow-container {
    margin-left: 1vw;
  }
  .slider_11 {
    width: 100vw;
    height: 390px !important;
  }
  .slider_12 {
    width: 100vw;
    height: 390px !important;
  }
  .slider_13 {
    width: 100vw;
    height: 390px !important;
  }
  .second_content_slider1 {
    margin-top: 100px;
  }
  .heading_second_slide h1 {
    line-height: 43px;
    font-weight: 400;
    font-size: 40px;
    margin-right: 10px;
  }
  .heading_second_slide {
    margin-right: 20px;
  }
  .heading2_second_slide {
    margin-right: 36px;
  }
  .left_conetnt_offer1 {
    width: 60%;
  }
  .next {
    right: 10px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1366px) {
  .slideshow-container {
    margin-left: 1vw;
  }
  .slider_11 {
    width: 100vw;
    height: 390px !important;
  }
  .slider_12 {
    width: 100vw;
    height: 390px !important;
  }
  .slider_13 {
    width: 100vw;
    height: 390px !important;
  }
  .second_content_slider1 {
    margin-top: 100px;
  }
  .heading_second_slide h1 {
    line-height: 43px;
    font-weight: 400;
    font-size: 40px;
    margin-right: 10px;
  }
  .heading_second_slide {
    margin-right: 20px;
  }
  .heading2_second_slide {
    margin-right: 36px;
  }
  .left_conetnt_offer1 {
    width: 60%;
  }
  .next {
    right: 10px;
  }
}
