
.input_field_contact_form {
  display: flex;
  column-gap: 1vw;
  margin-bottom: 1vw;
}
.input_field_contact_form input {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 3px;

  padding-left: 0.3vw;
  background-color: #ffffff;
  width: 20vw;
  height: 5vh;
  border: none;
  border-color: #b1afaf;
  color: #000;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.input_field_contact_form select {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 3px;

  padding-left: 0.3vw;
  background-color: #ffffff;
  width: 20vw;
  height: 5vh;
  border: none;
  border-color: #b1afaf;
  color: #000;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.text_area textarea {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 3px;
  padding-left: 0.3vw;
  background-color: #ffffff;
  width: 41.3vw;
  height: 19vh;
  border: none;
  border-color: #b1afaf;
  color: #000;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  /* resize: vertical; */
  font-family: inherit;
}
.submit_button button {
  margin-top: 2vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 41.7vw;
  height: 3vw;
  font-size: 1.4vw;
  color: #e9e8e8;
  padding-left: 0.15vw;
  background: linear-gradient(0.25turn, #9a17cd, #8f1bac, #7e21a0);
  border: none;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  cursor: pointer;
}
.address img{
    width: 5%;
}
.location{
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.header__text{
    font-size: 30px;
}
.location-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    
}
.conatct_title{
    font-size: 50px;
}

.contact_messages{
    font-size: 26px !important;
  }

@media screen and (max-width: 575px) {
  .contact-root-container {
    margin-top: 6vh;
    background: #ffffff;
    min-height: 100vh;
    height: 100%;
  }

  .address-container {
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    width: 80%;
  }

  .map-outside {
    background-color: rgb(62, 65, 63);
    padding: 10px;
    height: 500px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 6px 6px 6px #9e9e9e;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 80px;
  }

  .map-container {
    height: 480px;
    width: 100%;
  }

  .address-outside {
    background-color: rgb(62, 65, 63);
    padding: 10px;
    height: 500px;
    width: 100%;
    margin-right: 100px;
    margin-left: 10px;
    border-radius: 10px;
    box-shadow: 6px 6px 6px #9e9e9e;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 10px;
  }

  .location-container {
    height: 480px;
    width: 100%;
    text-align: center;
    background-color: white;
    word-spacing: normal;
  }

  .bank_details_info {
    word-spacing: normal;
  }

  .address-header {
    font-size: medium;
    text-align: center;
    word-spacing: normal;
  }

  .address-content {
    font-size: small;
  }

  .address-logo {
    height: 200px;
    width: 200px;
  }
  .contact_form{
    flex-direction: column;

    padding-left: 1rem;
    padding-top: 0;
  }
  .conatct_title{
    font-size: 35px;
  }
  .contact_messages{
    font-size: 20px !important;
  }
  .address img{
    width: 10%;
}
.header__text {
    font-size: 25px;
}
.input_field_contact_form{
    flex-direction: column;
    
}
.input_field_contact_form input{
    width: 100%;
    margin-top: 10px;

}
.input_field_contact_form select{
    width: 100%;
    margin-top: 10px;
}
.text_area textarea{
    width: 100%;
    font-size: 18px;
    margin-top: 10px;
}
.submit_button button{
    width: 100%;
    height: 40px;
    font-size: 20px;
}
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .contact-root-container {
    margin-top: 10vh;
    background: #ffffff;
    min-height: 100vh;
    height: 100%;
  }

  .address-container {
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    width: 80%;
  }

  .map-outside {
    background-color: rgb(62, 65, 63);
    padding: 10px;
    height: 500px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 6px 6px 6px #9e9e9e;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 80px;
  }

  .map-container {
    height: 480px;
    width: 100%;
  }

  .address-outside {
    background-color: rgb(62, 65, 63);
    padding: 10px;
    height: 500px;
    width: 100%;
    margin-right: 100px;
    margin-left: 10px;
    border-radius: 10px;
    box-shadow: 6px 6px 6px #9e9e9e;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 10px;
  }

  .location-container {
    height: 480px;
    width: 100%;
    text-align: center;
    background-color: white;
  }

  .address-header {
    font-size: medium;
    word-spacing: normal;
  }

  .address-content {
    font-size: small;
  }

  .address-logo {
    height: 200px;
    width: 200px;
  }
  
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .contact-root-container {
    margin-top: 10vh;
    background: #ffffff;
    min-height: 100vh;
    height: 100%;
  }

  .address-container {
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    width: 80%;
  }

  .map-outside {
    background-color: rgb(62, 65, 63);
    padding: 10px;
    height: 500px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 6px 6px 6px #9e9e9e;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 80px;
  }

  .map-container {
    height: 480px;
    width: 100%;
  }

  .address-outside {
    background-color: rgb(62, 65, 63);
    padding: 10px;
    height: 500px;
    width: 100%;
    margin-right: 100px;
    margin-left: 10px;
    border-radius: 10px;
    box-shadow: 6px 6px 6px #9e9e9e;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 10px;
  }

  .location-container {
    height: 480px;
    width: 100%;
    text-align: center;
    background-color: white;
  }

  .address-header {
    font-size: medium;
    word-spacing: normal;
  }

  .address-content {
    font-size: small;
  }

  .address-logo {
    height: 200px;
    width: 200px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-root-container {
    margin-top: 10vh;
    background: #ffffff;
    min-height: 100vh;
    height: 100%;
  }

  .address-container {
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  .map-outside {
    background-color: rgb(62, 65, 63);
    padding: 10px;
    height: 500px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 6px 6px 6px #9e9e9e;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 20px;
  }

  .map-container {
    height: 480px;
    width: 100%;
  }

  .address-outside {
    background-color: rgb(62, 65, 63);
    padding: 10px;
    height: 500px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 6px 6px 6px #9e9e9e;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 10px;
    margin-top: 50px;
  }

  .location-container {
    height: 480px;
    width: 100%;
    text-align: center;
    background-color: white;
  }

  .address-header {
    font-size: medium;
    word-spacing: normal;
  }

  .address-content {
    font-size: small;
  }

  .address-logo {
    height: 200px;
    width: 200px;
  }
}

@media screen and (min-width: 1200px) {
  .contact-root-container {
    margin-top: 10vh;
    background: #ffffff;
    min-height: 100vh;
    height: 100%;
  }

  .address-container {
    display: flex;
    margin-top: 50px;
  }

  .map-outside {
    background-color: rgb(62, 65, 63);
    padding: 10px;
    height: 500px;
    width: 50%;
    margin-left: 100px;
    border-radius: 10px;
    box-shadow: 6px 6px 6px #9e9e9e;
    margin-left: 100px;
    margin-right: 10px;
  }

  .map-container {
    height: 480px;
    width: 100%;
  }

  .address-outside {
    background-color: rgb(62, 65, 63);
    padding: 10px;
    height: 500px;
    width: 50%;
    margin-right: 100px;
    margin-left: 10px;
    border-radius: 10px;
    box-shadow: 6px 6px 6px #9e9e9e;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .location-container {
    height: 480px;
    width: 100%;
    text-align: center;
    background-color: white;
  }

  .address-header {
    font-size: larger;
  }

  .address-content {
    font-size: medium;
  }

  .address-logo {
    height: 180px;
    width: 180px;
  }
}
