.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* RIpple */

/* Continuous slide in from above and return */
@keyframes continuousSlideIn {
  0% {
    transform: translateY(-2rem);
    opacity: 0;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-2rem);
    opacity: 0;
  }
}

/* Continuous ripple effect on the target item */
@keyframes continuousRippleEffect {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Apply continuous animations */
.continuous-slide-in {
  animation: continuousSlideIn 2s infinite ease-in-out;
}

.ripple-target {
  animation: continuousRippleEffect 1.5s infinite ease-in-out;
}

/* text animation */
@keyframes textColorAnimation {
  0% {
    color: #ffffff; /* Start color */
  }
  50% {
    color: #f59e0b; /* Mid color (orange) */
  }
  100% {
    color: #ffffff; /* End color */
  }
}

.animate-text-color {

  animation: textColorAnimation 2s infinite; /* Adjust duration and repetition */
}


:where(.css-dev-only-do-not-override-ccdg5a).ant-modal .ant-modal-close {
  position: absolute;
  top: 12px;
  right: 3px !important;
  inset-inline-end: 12px;
  z-index: 1010;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.2s, background-color 0.2s;
}