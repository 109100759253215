/* The secret sauce that enables gradient rotation. NOTE: does not work in all browsers. https://caniuse.com/?search=%40property */
@property --border-angle-1 {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0deg;
}

@property --border-angle-2 {
  syntax: "<angle>";
  inherits: true;
  initial-value: 90deg;
}

@property --border-angle-3 {
  syntax: "<angle>";
  inherits: true;
  initial-value: 180deg;
}

/* sRGB color. */
:root {
  --bright-blue: rgb(0, 100, 255);
  --bright-green: rgb(76, 0, 255);
  --bright-red: rgb(255, 0, 0);
  --background: black;
  --foreground: white;
  --border-size: 2px;
  --border-radius: 0.4em;
}

/* Display-P3 color, when supported. */
@supports (color: color(display-p3 1 1 1)) {
  :root {
    --bright-blue: color(display-p3 0 0.1 1);
    --bright-green: color(display-p3 0.1 1 0);
    --bright-red: color(display-p3 1 0 0);
  }
}

@keyframes rotateBackground {
  to {
    --border-angle-1: 360deg;
  }
}

@keyframes rotateBackground2 {
  to {
    --border-angle-2: -270deg;
  }
}

@keyframes rotateBackground3 {
  to {
    --border-angle-3: 540deg;
  }
}

.telephone {
  --border-angle-1: 0deg;
  --border-angle-2: 90deg;
  --border-angle-3: 180deg;
  color: inherit;
  font-size: 15px;
  font-family: inherit;
  border: 0;
  padding: var(--border-size);
  display: flex;
  width: max-content;
  border-radius: var(--border-radius);
  background-color: transparent;
  background-image: conic-gradient(
      from var(--border-angle-1) at 10% 15%,
      transparent,
      var(--bright-blue) 10%,
      transparent 30%,
      transparent
    ),
    conic-gradient(
      from var(--border-angle-2) at 70% 60%,
      transparent,
      var(--bright-green) 10%,
      transparent 60%,
      transparent
    ),
    conic-gradient(
      from var(--border-angle-3) at 50% 20%,
      transparent,
      var(--bright-red) 10%,
      transparent 50%,
      transparent
    );

  animation: rotateBackground 2s linear infinite,
    rotateBackground2 10s linear infinite, rotateBackground3 10s linear infinite;
}

/* Change this background to transparent to see how the gradient works */
.telephone div {
  background: linear-gradient(
    90deg,
    rgb(17, 92, 143) 0%,
    rgb(143, 30, 95) 100%
  );
  padding: 10px 5px;
  border-radius: calc(var(--border-radius) - var(--border-size));
  color: var(--foreground);
  
}

@font-face {
  font-family: "Aspekta";
  font-weight: normal;
  src: url("https://assets.codepen.io/240751/Aspekta-300.woff2") format("woff2");
}
button a {
  padding: 0 !important;
  color: white !important;
}

.topnav {
  overflow: hidden;
  /* background: linear-gradient(0.25turn, #9a17cd, #771191, #aa21db); */
  
  position: fixed; /* Add this */
  top: 0; /* Add this */
  z-index: 1000; /* Add this */
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1vw;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}
.company img {
  width: 25px;
  height: 25px;
  margin-bottom: 4px;
}
.company {
  display: flex;
  align-items: center ;
}

.topnav.transparent {
  opacity: 0.8; /* Set initial opacity */
}
/* .telephone {
  display: flex !important;
  align-items: center;
  justify-content: center;
  column-gap: 0.4vw;
  background: linear-gradient(
    90deg,
    rgb(17, 92, 143) 0%,
    rgb(143, 30, 95) 100%
  );
  transition: all 1s ease-out;
  box-shadow: 0px 0px 4px gray;
  color: white !important;
  border-radius: 0.5vw;
  margin-left: 2vw;
} */
button div:hover {
  background: linear-gradient(
    90deg,
    rgb(143, 30, 95) 0%,
    rgb(17, 92, 143) 100%
  );
}
.company {
  margin-right: 19vw !important;
}

.topnav a {
  float: left;
  display: block;
  color: #771191;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  margin-right: 1vw;
  font-weight: bold;
}

.nav-items:hover {
  color: gray !important;
}

.topnav .icon {
  display: none;
}
.topnav a.active {
  background-color: #04aa6d;
  color: white;
}

.activeNav {
  color: #1d4ed8  !important;
}
@media screen and (max-width: 360px) {
  .topnav {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .topnav a:not(:first-child) {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
    margin-left: 68.3vw;
  }
  .telephone {
    display: none !important;
  }
}

@media screen and (max-width: 360px) {
  .topnav.responsive {
    position: relative;
    width: 110%;
  }
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .responsive .telephone {
    display: block !important;
    font-size: 14px !important;
    margin-left: 4vw !important;

    text-align: center !important;
    border-radius: 2vw;
  }
}

/* @media screen and (max-width: 768px) {
  .company {
    margin-right: 0vw;
  }

  .topnav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
  }

  .topnav a:not(:first-child) {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
    margin-left: 52vw;
  }
  .telephone {
    display: none !important;
  }
} */

@media screen and (min-width: 576px) and (max-width: 768px) {
  .topnav.responsive {
    position: relative;
    width: 98%;
  }
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .responsive .telephone {
    display: block !important;
    font-size: 14px !important;
    margin-left: 4vw !important;

    text-align: center !important;
    border-radius: 2vw;
  }
}

@media screen and (max-width: 575px) {
  .company {
    margin-right: 0vw;
  }

  .topnav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: fixed;
  }

  .topnav a:not(:first-child) {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
    margin-left: 20vw;
    margin-top: 1vw;
  }
  .telephone {
    display: none !important;
  }
}

@media screen and (max-width: 575px) {
  .topnav.responsive {
    position: fixed;
    width: 98%;
  }
  .topnav.responsive .icon {
    position: absolute;
    right: 10px;
    top: 2vw;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .responsive .telephone {
    display: block !important;
    font-size: 14px !important;
    margin-left: 4vw !important;

    text-align: center !important;
    border-radius: 2vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .navmenu {
    display: flex;
    align-items: center;
  }
  .company {
    margin-right: 0vw !important;
    margin-top: 10px;
  }

  .topnav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: fixed;
  }
  .topnav a {
    margin-right: 0;
    padding: 14px 10px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .company {
    margin-right: 6vw !important;
    margin-top: -5px;
  }
  .topnav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: fixed;
  }
  .topnav a {
    margin-right: 0;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
}

@media screen and (min-width: 1200px) {
}
