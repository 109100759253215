#gallery_container {
  height: auto !important;
  min-height: 100vh;
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.product_header {
  margin-top: 80px;
  background: linear-gradient(
    to right,
    #891299 20%,
    #4e056d 40%,
    #b741c2 60%,
    #b40cc4 80%
  );
  background-size: 200% auto;
  color: #333333;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  margin-bottom: 10px;
}

.product_image {
  width: 100%;
  border: 1px solid black;
 
}
.image_section_gallery {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

@media screen and (min-width: 300px) and (max-width: 767px) {
  #gallery_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .product_header {
    margin-top: 70px;
  }
  .product_image {
    width: 90%;
  }
  .image_section_gallery {
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    justify-content: center;
    margin-left: 10vw;
  }
}
